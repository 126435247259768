<template>
  <div>
    bar chart for reader {{ reader }} and input {{ input }}
  </div>
</template>
<script>
export default {
  props: ['reader', 'input']
}
</script>
