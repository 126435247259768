<template>
  <div>
    <!--
    <apexchart :id="serial + '-' + input" width="100%" type="radialBar" :options="options" :series="value"></apexchart>
    -->
  </div>
</template>
<script>
export default {
  props: ['readers', 'serial', 'input'],
  data () {
    return {
      readerData: null,
      inputData: null,
      series: [],
      options: {}
    }
  },
  sockets: {
    // Receive live data from RTS
    readerData (data) {
      if (data.serial === this.serial && data.input.toUpperCase() === this.input) {
        console.log('Component caught value ' + data.value + ' - ' + parseInt(data.value))
        this.value = [parseInt(data.value)]
        console.log('Chart value is now ' + this.value[0])
      }
    }
  },
  mounted () {
    this.getReader()
  },
  methods: {
    getReader () {
      this.readerData = this.readers.find(reader => reader.serial === this.serial)
      this.inputData = this.readerData.inputs.find(input => input.input === this.input)
      // this.options.labels.push(this.inputData.name)
    }
    // TEST
  }
}
</script>
