<template>
  <div id="app">
    <!-- Main layout -->
    <b-container fluid class="vh-100" v-if="$route.meta.fullLayout">
      <b-row align-v="stretch" class="vh-100">
        <!-- Workspace sidebar -->
        <b-col class="sidemenu p-0 pb-2" md="2" v-if="!settings && $store.state.user.workspace">
          <!-- Workspace view -->
          <div>
            <h6 class="d-block p-2" v-b-toggle.accordion-1>
              <router-link :to="'/c/' + $store.state.user.workspace.id" class="button">
                {{ $store.state.user.workspace.name }}
              </router-link>
              <b-icon-gear class="float-right mr-2 button" v-b-toggle.sidebar-backdrop></b-icon-gear>
            </h6>

            <b-nav vertical>
              <b-nav-item v-for="group in $store.state.user.workspace.groups" :key="group.id" class="p-0 m-0">
                <router-link :to="'/w/' + $store.state.user.workspace.id + '/' + group.id" class="d-block">
                  {{ group.name }}
                  <b-icon-circle-fill class="float-right" style="color: green"></b-icon-circle-fill>
                </router-link>
              </b-nav-item>
            </b-nav>
          </div>

          <!-- User sidebar -->
          <b-sidebar id="sidebar-backdrop" :title="$store.state.user.profile.name" width="320px" bg-variant="dark" text-variant="light" backdrop-variant="dark" backdrop shadow>
            <!-- User options -->
            <b-nav vertical class="mb-4">
              <b-nav-item class="p-0 m-0">
                <router-link to="/logout" class="d-block">
                  Sign Out
                  <b-icon-door-open class="float-right"></b-icon-door-open>
                </router-link>
              </b-nav-item>
            </b-nav>

            <!-- User companies -->
            <h5 class="pl-2">Workspaces</h5>
            <b-nav vertical>
              <b-nav-item v-for="workspace in $store.state.user.workspaces" :key="workspace.id" class="p-0 m-0">
                <router-link :to="'/w/' + workspace.id" class="d-block">
                  {{ workspace.name }}
                  <b-icon-folder class="float-right"></b-icon-folder>
                </router-link>
              </b-nav-item>
            </b-nav>
          </b-sidebar>
        </b-col>
        <!-- Content -->
        <b-col>
          <!-- Connection alert -->
          <b-alert show class="mt-4" variant="danger" v-if="$socket.disconnected">
            <b-icon icon="arrow-clockwise" animation="spin" font-scale="1.5" class="float-right"></b-icon>
            No connection with real-time server... reconnecting.
          </b-alert>

          <!-- Router -->
          <div class="px-8">
            <transition name="fade" mode="out-in">
              <router-view :key="$route.fullPath"/>
            </transition>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- Simple layout -->
    <div v-if="!$route.meta.fullLayout">
      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath"/>
      </transition>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      settings: false,
      workspace: null
    }
  },
  sockets: {
    connect () {
      console.log('Connected to RTS')
    },
    disconnect () {
      console.log('RTS connection lost... Trying to reconnect.')
    },
    general (data) {
      switch (data.type) {
        case 'kill':
          console.log('Forced log-out!')
          this.$router.push('/login')
          break
        default:
          console.log(data.type + ' => ' + data.msg)
          break
      }
    }
  }
}
</script>
