<template>
  <div v-if="$store.state.user.workspace">
    <h1 class="text-3xl">{{ $store.state.user.workspace.name }}</h1>
    Workspace overview or smth?
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  watch: {
    '$route.params.workspace_id': function (serial) {
      this.getWorkspace()
    }
  },
  mounted () {
    this.getWorkspace()
  },
  methods: {
    getWorkspace () {
      this.$store.state.user.workspace = this.$store.state.user.workspaces.find(workspace => workspace.id === parseInt(this.$route.params.workspace_id))
    }
  }
}
</script>
