import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueApexCharts from 'vue-apexcharts'
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
import './assets/styles/index.css'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

const socket = io(store.state.rts)
Vue.use(VueSocketIOExt, socket)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueApexCharts)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
