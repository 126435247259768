import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    api: 'https://api.effu.ee',
    rts: 'https://rts.effu.ee:3000',
    // rts: 'http://localhost:3000',
    user: {
      token: null,
      profile: [],
      workspaces: [],
      workspace: null
    }
  },
  mutations: {
    // Save user token in state
    setToken (state, payload) {
      state.user.token = payload
      this.dispatch('loadUser')
    },
    // Save user data in state
    setUser (state, payload) {
      state.user.profile = payload.profile
      state.user.workspaces = payload.workspaces
      // Push to first workspace dashboard
      router.push('/w/' + state.user.workspaces[0].id)
    },
    setWorkspace (state, payload) {
      state.user.workspace = payload
    }
  },
  actions: {
    loadUser () {
      if (this.state.user.token) {
        const _this = this
        axios.post(this.state.api + '/user/overview', {}, {
          headers: { Authorization: 'Bearer ' + this.state.user.token }
        })
          .then(function (response) {
            _this.commit('setUser', response.data)
          })
          .catch(e => {
            _this.commit('setToken', null)
          })
      }
    }
  },
  modules: {
  }
})
