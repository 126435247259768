<template>
  <div v-if="group">
    <h1 class="text-3xl mb-3">
      <b-button v-if="edit" @click="setLayout" class="float-right mt-2" size="lg" variant="primary">Save layout</b-button>
      <b-button v-if="!edit" @click="edit = !edit" class="float-right mt-3" size="sm" variant="outline-primary" title="Edit layout"><b-icon-pencil></b-icon-pencil></b-button>
      {{ group.name }}
    </h1>

    <!-- Add item to layout -->
    <b-card v-if="edit" bg-variant="dark" text-variant="light" class="mb-2">
      <b-card-body>
        <h4>Add chart</h4>
        <b-row>
          <!-- Chart type -->
          <b-col>
            <b-form-group id="input-group-type" label="Chart type" label-for="input-type">
              <b-form-select id="input-type" v-model="add.type" :options="addOptions.types" value-field="value" text-field="name"></b-form-select>
            </b-form-group>
          </b-col>

          <!-- Select reader -->
          <b-col>
            <b-form-group v-if="add.type" id="input-group-reader" label="Select reader" label-for="input-reader">
              <b-form-select id="input-reader" v-model="add.serial" :options="group.readers" @change="setNewItemReader()" value-field="serial" text-field="serial"></b-form-select>
            </b-form-group>
          </b-col>

          <!-- Input -->
          <b-col>
            <b-form-group v-if="add.serial" id="input-group-input" label="Select input" label-for="input-input">
              <b-form-select id="input-input" v-model="add.input" :options="addOptions.inputs" value-field="input" text-field="name"></b-form-select>
            </b-form-group>
          </b-col>

          <!-- Add -->
          <b-col>
            <b-button v-if="add.type && add.serial && add.input" variant="primary" class="mt-4" block @click="addLayoutItem">Add to view</b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Group layout -->
    <grid-layout style="margin: -10px;" v-if="layout && readers" :layout.sync="layout" :col-num="12" :row-height="30" :is-draggable="edit" :is-resizable="edit" :is-mirrored="false" :vertical-compact="true" :margin="[10, 10]" :use-css-transforms="true">

      <grid-item v-for="item in layout" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :key="item.i">
        <b-button v-if="edit" size="sm" class="m-2 position-absolute float-right removeItem" @click="removeLayoutItem(item.i)">
          <b-icon-trash></b-icon-trash>
        </b-button>
        <component v-bind:is="item.type" :serial="item.reader" :input="item.input" :readers="group.readers"></component>
      </grid-item>

    </grid-layout>

    <div class="block mb-2 mt-4">Send fake data to group readers:</div>
    <div class="block mb-2"
         v-for="reader in group.readers"
         :key="reader.id">
      <h4>{{ reader.serial }}</h4>
      <b-button variant="outline-primary" class="mr-1" @click="fakeAgent(reader.serial, 'boolean', 'D0')">boolean
      </b-button>
      <b-button variant="outline-primary" class="mr-1" @click="fakeAgent(reader.serial,  'integer', 'A0')">integer
      </b-button>
      <b-button variant="outline-primary" class="mr-1" @click="fakeAgent(reader.serial,  'float','R0')">float</b-button>
    </div>
  </div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout'
import BarChart from '../components/charts/BarChart'
import LineChart from '../components/charts/LineChart'
import GaugeChart from '../components/charts/GaugeChart'
import SwitchLight from '../components/charts/SwitchLight'
import axios from 'axios'

export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    BarChart,
    LineChart,
    GaugeChart,
    SwitchLight
  },
  data () {
    return {
      group: null,
      readers: [],
      edit: false,
      add: {
        serial: null,
        input: null,
        type: null
      },
      addOptions: {
        inputs: [],
        types: [
          { value: 'BarChart', name: 'Bar chart' },
          { value: 'LineChart', name: 'Line chart' },
          { value: 'GaugeChart', name: 'Gauge' },
          { value: 'SwitchLight', name: 'Status light' }
        ]
      },
      layout: []
    }
  },
  sockets: {
    // Receive live data from RTS
    // readerData (data) {
    // console.log(data)
    // }
  },
  mounted () {
    this.getGroup()

    this.readers = this.group.readers.map(reader => {
      return reader.serial
    })

    this.getLayout()

    // Subscribe to readers data
    if (this.readers.length !== 0) {
      this.subscribe()
    } else {
      console.log('No readers in group!')
    }
  },
  destroyed () {
    // Unsubscribe from readers data on navigation
    if (this.readers.length !== 0) {
      this.unsubscribe()
    }
  },
  methods: {
    getGroup () {
      this.workspace = this.$store.state.user.workspaces.find(workspace => workspace.id === parseInt(this.$route.params.workspace_id))
      this.group = this.workspace.groups.find(group => group.id === parseInt(this.$route.params.group_id))
    },
    // Fake a data input from agent -> RTS making it save the data to database and broadcasting to the reader room
    fakeAgent (serial, type, input) {
      let value
      switch (type) {
        case 'boolean':
          value = Math.random() < 0.5
          break
        case 'integer':
          value = Math.floor(Math.random() * 9999)
          break
        case 'float':
          value = Math.random() * 9999
          break
      }
      this.$socket.client.emit('data', {
        serial: serial,
        input: input,
        value: value,
        timestamp: Date.now()
      })
    },
    getLayout () {
      const _this = this
      axios.get(this.$store.state.api + '/group/layout?group=' + this.group.id, { headers: { Authorization: 'Bearer ' + this.$store.state.user.token } })
        .then(function (response) {
          console.log(response.data)
          if (response.data.layout) {
            _this.layout = JSON.parse(response.data.layout)
          } else {
            console.log('No layout available for group!')
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    addLayoutItem () {
      if (this.add.serial && this.add.input && this.add.type) {
        this.layout.push({
          x: (this.layout.length * 6) % (this.colNum || 12),
          y: this.layout.length + (this.colNum || 12), // puts it at the bottom
          w: 6,
          h: 4,
          i: this.layout.length + 1,
          reader: this.add.serial,
          input: this.add.input,
          type: this.add.type
        })
      }
    },
    removeLayoutItem (i) {
      this.layout = this.layout.filter(item => item.i !== i)
    },
    setLayout () {
      console.log(JSON.stringify(this.layout))
      const _this = this
      axios.post(this.$store.state.api + '/group/layout', {
        group_id: _this.group.id,
        layout: JSON.stringify(this.layout)
      }, { headers: { Authorization: 'Bearer ' + this.$store.state.user.token } })
        .then(function (response) {
          _this.edit = false
          _this.layout = JSON.parse(response.data.layout)
        })
        .catch(e => {
          console.log(e)
        })
    },
    setNewItemReader () {
      this.add.input = null
      const reader = this.group.readers.find(reader => reader.serial === this.add.serial)
      this.addOptions.inputs = reader.inputs
    },
    subscribe () {
      console.log('Subscribed to readers ' + this.readers)
      this.$socket.client.emit('subscribe', {
        token: this.$store.state.user.token,
        readers: this.readers
      })
    },
    unsubscribe () {
      console.log('Unsubrscribed from readers ' + this.readers)
      this.$socket.client.emit('unsubscribe', { readers: this.readers })
    }
  }
}
</script>
