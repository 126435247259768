<template>
  <div class="v-center">
    <b-container style="width: 350px">
      <!-- Title -->
      <b-row>
        <b-col>
          <h1>Login</h1>
        </b-col>
      </b-row>
      <!-- Login box -->
      <b-row>
        <b-col id="box-overlay" class="pt-4 pb-3">
          <b-alert v-if="error" show variant="danger">
            <h5 class="text-xl">Login failed!</h5>
            {{ error }}
          </b-alert>

          <form @submit.prevent="login">
            <!-- E-mail -->
            <b-row class="mb-3">
              <b-col>
                <b-input type="email" autocomplete="email" v-model="email" size="lg" placeholder="E-Mail address"></b-input>
              </b-col>
            </b-row>

            <!-- Password -->
            <b-row class="mb-3">
              <b-col>
                <b-input type="password" autocomplete="current-password" v-model="password"  size="lg"  placeholder="Password"></b-input>
              </b-col>
            </b-row>

            <!-- Submit -->
            <b-row class="mb-3">
              <b-col>
                <b-overlay :show="loading" rounded opacity="0.6" spinner-small spinner-variant="primary">
                  <b-button :disabled="loading" block size="lg" variant="primary" type="submit">Log In</b-button>
                </b-overlay>
              </b-col>
            </b-row>

            <!-- Or register -->
            <b-row>
              <b-col align="center">
                <div class="separator mb-2">No account?</div>
                <b-button to="/register" block size="sm" variant="light">Create account</b-button>
              </b-col>
            </b-row>
          </form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      loading: false,
      email: null,
      password: null,
      error: null
    }
  },
  created () {
    //
  },
  methods: {
    login () {
      const _this = this
      _this.loading = true
      axios.post(_this.$store.state.api + '/auth/login', {
        email: _this.email,
        password: _this.password
      })
        .then(function (response) {
          console.log(response.data)
          _this.$store.commit('setToken', response.data.access_token)
          // _this.loading = false
        })
        .catch(function (error) {
          console.log('Login failed')
          if (error.response.status === 401) {
            _this.error = 'Something went wrong...'
            // _this.$toast.error('Please check the e-mail and password combination used', { timeout: 3500 })
          } else {
            _this.error = 'Something went wrong...'
            // _this.$toast.warning('We are experiencing some technical difficulties. Please try again later.')
          }
          // _this.loading = false
        })
    }
  }
}
</script>
