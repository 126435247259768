import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Workspace from '../views/Workspace.vue'
import Group from '../views/Group.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: Login,
    meta: {
      requiresAuth: false,
      fullLayout: false
    }
  },
  {
    path: '/w/:workspace_id',
    component: Workspace,
    meta: {
      requiresAuth: true,
      fullLayout: true
    }
  },
  {
    path: '/w/:workspace_id/:group_id',
    component: Group,
    meta: {
      requiresAuth: true,
      fullLayout: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
