<template>
  <div style="height: 100%;">
    <div style="border-radius: 10px; width: 100%; height: 100%;" :id="serial + '-' + input" :style="'background-color:' + status + ';'">
      Reader: {{ serial }}<br>
      Input: {{ input }}<br>
      State: {{ value }}
    </div>
  </div>
</template>
<script>
export default {
  props: ['readers', 'serial', 'input'],
  data () {
    return {
      readerData: null,
      inputData: null,
      value: false,
      status: '#2ECC40'
    }
  },
  sockets: {
    // Receive live data from RTS
    readerData (data) {
      console.log(data)
      if (data.serial.toString() === this.serial.toString() && data.input.toUpperCase() === this.input) {
        if (data.value === true) {
          this.status = '#2ECC40'
        } else {
          this.status = '#FF4136'
        }
        this.value = data.value
        console.log('Chart value is now ' + this.value)
      }
    }
  },
  mounted () {
    this.getReader()
  },
  methods: {
    getReader () {
      this.readerData = this.readers.find(reader => reader.serial === this.serial)
      this.inputData = this.readerData.inputs.find(input => input.input === this.input)
      // this.options.labels.push(this.inputData.name)
    }
  }
}
</script>
